import {colors} from "@c10h/colors";
import {ReadableSlotTimeBySpecialtyId} from "@components/ReadableSlotTimeBySpecialtyId";
import router from "next/router";
import {useTranslation} from "ni18n";
import React from "react";
import {trackToPatient} from "src/components/_common/Carbon";
import {SpecialtyId} from "src/constants/specialtyIds";
import {useTypedSelector} from "src/store";

import {PracticeId} from "../../../constants/practiceIds";
import {LazyComp} from "../../_common/LazyComp";
import NextImage from "../NextImage";
import TextMeApp from "../TextMeApp";
import {Btn} from "../V5Comp";

interface DownloadProps extends React.HTMLAttributes<HTMLDivElement> {
  trackingLabel?: string;
}

const Download: React.FunctionComponent<DownloadProps> = ({trackingLabel}) => {
  const {practices, isMobile} = useTypedSelector(({config}) => config);
  const carbonPractice = practices?.findById(PracticeId.CARBON);
  const {downloadDeepLink, slug} = carbonPractice || {
    downloadDeepLink: "https://crbn.page.link/nijKU2YXJH",
    slug: "direct-urgent-care",
  };
  const i18n = useTranslation();

  const downloadLinkAnalyticsConfig = () =>
    trackToPatient({
      ga: {
        category: "Download app",
        action: "SMS from virtual care scheduling force DL",
        label: trackingLabel || router.pathname,
      },
    });

  const downloadLinkAnalyticsConfigMobile = () => {
    trackToPatient({
      ga: {
        category: "Download app",
        action: "Link to app store",
        label: trackingLabel || router.pathname,
      },
    });
  };

  const header = i18n.t("Get treated right from your phone.");

  const downloadImagePath = "reason/download/main";
  const mobileImagePath = "reason/download/main_mobile";
  const checkmarkSrc = "reason/download/check";

  const defaultItems = [
    i18n.t("Book same-day appointments"),
    i18n.t("Talk to a provider in real time"),
    i18n.t("Get care from the comfort of home"),
    i18n.t("Most insurance accepted"),
  ];

  const mobileListItems = [
    i18n.t("Same-day appointments"),
    i18n.t("Chat wherever you are"),
    i18n.t("Accepts most insurance"),
  ];

  const listItems = isMobile ? mobileListItems : defaultItems;
  const id = "download-app-heading";

  return (
    <section aria-labelledby={id} className="ph32 ph8-md pv18 pv8-md ph4-f-xl pb0-f contrast-tbt">
      <div className="df fdrr maxw85 ma">
        <div className="fx df fdc aic-md">
          <LazyComp>
            <strong className="font-mb mb6 ttu fs12 darkGreen tac-md fsn">
              <ReadableSlotTimeBySpecialtyId specialtyId={SpecialtyId.VIRTUAL_URGENT_CARE} />
            </strong>
          </LazyComp>

          <h2 id={id} className="font-cm fs40 fs24-md mb6 maxw30 tac-md">
            {header}
          </h2>
          <div className="mb6 tac-md">
            {isMobile ? (
              <Btn
                bg={colors.darkGreen}
                color="#fff"
                fontSize={1}
                href={downloadDeepLink}
                onClick={downloadLinkAnalyticsConfigMobile}
              >
                {i18n.t("Get the App")}
              </Btn>
            ) : (
              <TextMeApp
                downloadDeepLink={downloadDeepLink}
                practiceSlug={slug}
                gA={downloadLinkAnalyticsConfig}
                inputStyle={{backgroundColor: colors.gray100 + 80}}
              />
            )}
          </div>
          <strong className="gray400 fs12 lh17 font-ml mb28 mb8-md tac-md font-mb ttu ls2">
            {i18n.t("Available in the App Store and Google Play")}
          </strong>
          <div className="df aic">
            {isMobile && (
              <div className="fx tac">
                <NextImage
                  src={mobileImagePath}
                  width={164}
                  height={313}
                  alt={i18n.t(
                    "An illustration of a mobile phone during a virtual appointment with a Carbon Health provider, who is smiling.",
                  )}
                />
              </div>
            )}
            <ul className="fx">
              {listItems.map((item, key) => (
                <li key={key} className="df aic mb14 mb6-md">
                  <div className="w6">
                    <NextImage
                      src={checkmarkSrc}
                      height={35}
                      width={31}
                      layout="responsive"
                      alt=""
                    />
                  </div>
                  <span className="gray800 fx font-cm fs24 fs16-md fs12-xs ml6 ml2-md lh14">
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {!isMobile && (
          <div className="fx dn-md mr8">
            <NextImage
              src={downloadImagePath}
              className="ase"
              alt={i18n.t(
                "An illustration of a mobile phone during a virtual appointment with a Carbon Health provider, who is smiling.",
              )}
              width="639"
              height="984"
              layout="responsive"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Download;
